import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { SectionTemplate } from '../../components';
import mask from '../../images/blob-shape-2.svg';
import { media } from '../../utils/mixins';

export const Landing = () => {
    const { bar } = useStaticQuery(query);
    return (
        <SectionTemplate
            headingText="Loyalty Program"
            paragraphText={`Our new loyalty programs that will keep the customer
                retention but also let allow their business to boost its
                customer acquisition.`}
            imageContent={<Image fluid={bar.childImageSharp.fluid} />}
        />
    );
};

const query = graphql`
    {
        bar: file(relativePath: { eq: "bar.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

const BackgroundSplash = styled.div`
    ${({ theme, opacity }) => {
        const svg1 = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="${
            theme.colors.primary
        }" fill-opacity="${
            opacity ? opacity : 1
        }" d="M0,160L48,186.7C96,213,192,267,288,282.7C384,299,480,277,576,229.3C672,181,768,107,864,106.7C960,107,1056,181,1152,197.3C1248,213,1344,171,1392,149.3L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>`;
        return `
background-repeat:no-repeat;
background-image:url('data:image/svg+xml;base64,${
            typeof window === 'undefined'
                ? Buffer.from(svg1, 'binary').toString('base64')
                : window.btoa(svg1)
        }');
`;
    }}
`;

const Image = styled(Img)`
    mask: url(${mask});
    mask-size: cover;
    max-width: 26rem;
    max-height: 26rem;
    width: 100%;
    height: auto;
    ${media.tablet`
        max-width: 20rem;
        max-height: 20rem;
    `}
`;
