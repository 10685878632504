import React from 'react';
import styled from 'styled-components';
import { SectionTemplate, Stack, PhoneBox } from '../../components';
import { transition, media } from '../../utils/mixins';
import { Heading, Paragraph } from '@cheapreats/react-ui';

export const Middle = () => {
    return (
        <SectionTemplate>
            <PhoneContainer active>
                <SPhoneBox border={true}>
                    <Content direction="column">
                        <Heading
                            type="h2"
                            color="primary"
                            bold
                            style={{ textAlign: 'center' }}
                        >
                            Social Loyalty Program
                        </Heading>
                        <Paragraph
                            size="normal"
                            color="black"
                            margin="auto auto auto 0"
                        >
                            Our loyalty program provides a new way to boost your
                            customer acquisition by maximizing the relationship
                            between existing customers and their friends.
                        </Paragraph>
                    </Content>
                </SPhoneBox>
            </PhoneContainer>
        </SectionTemplate>
    );
};

const SSection = styled.div`
    ${({ theme, opacity }) => {
        const svg2 = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="${
            theme.colors.primary
        }" fill-opacity="${
            opacity ? opacity : 1
        }" d="M0,160L40,154.7C80,149,160,139,240,165.3C320,192,400,256,480,240C560,224,640,128,720,96C800,64,880,96,960,90.7C1040,85,1120,43,1200,21.3C1280,0,1360,0,1400,0L1440,0L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path></svg>`;
        return `
background-repeat:no-repeat;
background-image:url('data:image/svg+xml;base64,${
            typeof window === 'undefined'
                ? Buffer.from(svg2, 'binary').toString('base64')
                : window.btoa(svg2)
        }');
`;
    }}
`;

const Content = styled(Stack)`
    ${media.tablet`
        text-align: center;
        padding-right: 0;
        margin-left: auto;
    `}
    ${({ align }) => `
    align-items: ${align};
    `}
`;

const PhoneContainer = styled.div`
    width: 25%;
    margin: auto;
    opacity: ${(props) => (props.active ? 1 : 0)};
    transform: ${(props) =>
        props.active ? 'translate(0)' : 'translate(200px)'};
    ${transition(['opacity', 'transform'])}
`;

const SPhoneBox = styled(PhoneBox)`
    padding: 20px;
    box-sizing: border-box;
    ${({ theme }) => `
background-color:${theme.colors.background};
`}
`;
