import React from 'react';
import { Landing, Middle, Last } from '../sections/loyaltyProgram';
import { Layout } from '../components';

const LoyaltyProgram = () => (
    <Layout title="Loyalty Program">
        <Landing />
        <Middle />
        <Last />
    </Layout>
);

export default LoyaltyProgram;
